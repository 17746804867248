import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { CSSTransition } from "react-transition-group";
import DateCountdown from "react-date-countdown-timer";
import "./App.css";

import georgesImage from "./images/georges.png";
import toucanImage from "./images/toucan.png";

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const feuillestl = (x, y) => `translate3d(${x / 100}px,${y / 100}px,0)`;
const feuillestr = (x, y) => `translate3d(${x / 90}px,${y / 90}px,0)`;
const feuillesbl = (x, y) => `translate3d(${x / 60}px,${y / 60}px,0)`;
const feuillesbr = (x, y) => `translate3d(${x / 70}px,${y / 70}px,0)`;
const georgesTrans = (x, y) => `translate3d(${x / 10}px,${y / 40}px,0)`;
const toucanTrans = (x, y) => `translate3d(${x / 20}px,${y / 3.5}px,0)`;

function App() {
  const [inProp, setInProp] = useState(false);
  const [isUp, setIsUp] = useState(false);

  const handleUp = () => {
    if (!isUp) setIsUp(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setInProp(true);
    }, 300);
  });

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <div className="App relative flex justify-center items-center h-screen w-full overflow-hidden">
      <div
        className="absolute top-0 left-0 bottom-0 right-0 z-1"
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <CSSTransition in={inProp} timeout={800} classNames="App-feuilles-t-l">
          <animated.div
            className="App-feuilles-t-l absolute background-no-repeat background-top-left"
            style={{ transform: props.xy.interpolate(feuillestl) }}
          />
        </CSSTransition>
        <CSSTransition in={inProp} timeout={700} classNames="App-feuilles-t-r">
          <animated.div
            className="App-feuilles-t-r absolute background-no-repeat background-top-left"
            style={{ transform: props.xy.interpolate(feuillestr) }}
          />
        </CSSTransition>
        <CSSTransition in={inProp} timeout={600} classNames="App-feuilles-b-l">
          <animated.div
            className="App-feuilles-b-l absolute background-no-repeat background-top-left"
            style={{ transform: props.xy.interpolate(feuillesbl) }}
          />
        </CSSTransition>
        <CSSTransition in={inProp} timeout={800} classNames="App-feuilles-b-r">
          <animated.div
            className="App-feuilles-b-r absolute background-no-repeat background-top-left"
            style={{ transform: props.xy.interpolate(feuillesbr) }}
          />
        </CSSTransition>
        <CSSTransition in={inProp} timeout={1400} classNames="App-georges">
          <animated.div
            className="App-georges absolute"
            style={{ transform: props.xy.interpolate(georgesTrans) }}
          >
            <img src={georgesImage} alt="George" />
          </animated.div>
        </CSSTransition>
        <CSSTransition in={inProp} timeout={1000} classNames="App-toucan">
          <animated.div
            className="App-toucan absolute right-0"
            style={{ transform: props.xy.interpolate(toucanTrans) }}
          >
            <img src={toucanImage} alt="Toucan" />
          </animated.div>
        </CSSTransition>
      </div>
      <div className="relative z-2">
        <div className="flex flex-col items-center mb-4">
          <span className="text-green font-bold text-50px mb-2">1 an</span>
          <span className="text-green font-bold text-50px leading-60px mb-2">
            Gaspard
          </span>
          <hr style={{ width: "120px" }} className="border-brown m-4" />
          <span className="text-red font-bold  text-30px sm:text-40px leading-50px">
            Samedi 20 juin 2020
          </span>
          <div className="text-14px sm:text-20px text-green">
            {!isUp && (
              <DateCountdown
                dateTo="June 20, 2020 12:00:00 GMT+02:00"
                mostSignificantFigure="day"
                numberOfFigures={4}
                locales={[
                  "Année",
                  "Mois",
                  "Jour",
                  "Heure",
                  "Minute",
                  "Seconde",
                ]}
                locales_plural={[
                  "Années",
                  "Mois",
                  "Jours",
                  "Heures",
                  "Minutes",
                  "Secondes",
                ]}
                callback={handleUp}
              />
            )}
          </div>
        </div>
        <div className="text-center">
          <span className="text-brown font-bold text-20px sm:text-30px">
            Gaspard, Anthony et Flore
          </span>
        </div>
      </div>
    </div>
  );
}

export default App;
